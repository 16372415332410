<template>
  <div class="d-flex flex-column flex-root">
    <ConsensuHeader></ConsensuHeader>
    <PrivacyContent></PrivacyContent>
    <Footer></Footer>
    <ScrollTop></ScrollTop>
  </div>
</template>

<script>
  import ConsensuHeader from "@/components/TermsOfUse/Header";
  import PrivacyContent from "@/components/TermsOfUse/Content";
  import Footer from "@/components/Footer";
  import ScrollTop from "@/components/ScrollTop";

  export default {
    name: "TermsOfUse",
    components: {
      ConsensuHeader,
      Footer,
      PrivacyContent,
      ScrollTop,
    },
    mounted(){
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };
</script>
