<template>
  <div class="mt-20 mb-n20 position-relative z-index-2">
    <!--begin::Container-->
    <div class="container">
      <!--begin::Heading-->
      <!-- <div class="text-center mb-17">
        
        <h3
          class="fs-2hx text-dark mb-5"
          id="clients"
          data-kt-scroll-offset="{default: 125, lg: 150}"
        >O que nossos clientes dizem</h3>
        
        <div class="fs-5 text-muted fw-bold">
          Abaixo o depoimento de algumas empresas que ajudamos a proteger a privacidade de seus clientes/consumidores.
        </div>
        
      </div> -->
      <!--end::Heading-->
      <!--begin::Row-->
      <!-- <div class="row g-lg-10 mb-10 mb-lg-20">
        <div class="col-lg-4" v-for="(item,i) in testimonials" :key="i">
          
          <div
            class="d-flex flex-column justify-content-between h-lg-100 px-10 px-lg-0 pe-lg-10 mb-15 mb-lg-0"
          >
            
            <div class="mb-7">
              
              <div class="rating mb-6">
                <div
                  class="rating-label me-2 checked"
                  v-for="star in item.rating"
                  :key="star"
                >
                  <i class="bi bi-star-fill fs-5"></i>
                </div>
              </div>
              
              
              <div class="fs-2 fw-bolder text-dark mb-3">{{item.title}}</div>
              
              
              <div class="text-gray-500 fw-bold fs-4">{{item.text}}</div>
              
            </div>
            
            
            <div class="d-flex align-items-center">
              
              <div class="symbol symbol-circle symbol-50px me-5">
                <img :src="item.avatar" class alt />
              </div>
              
              
              <div class="flex-grow-1">
                <a
                  href="#"
                  class="text-dark fw-bolder text-hover-primary fs-6"
                >{{item.name}}</a>
                <span class="text-muted d-block fw-bold">{{item.company}}</span>
              </div>
              
            </div>
            
          </div>
          
        </div> -->
        <!--end::Col-->
      <!-- </div>  -->
      <!--end::Row-->
      <div
        class="d-flex flex-stack flex-wrap flex-md-nowrap card-rounded shadow p-8 p-lg-12 mb-n5 mb-lg-n13"
        style="background: linear-gradient(90deg, #20AA3E 0%, #03A588 100%);"
      >
        <!--begin::Content-->
        <div class="my-2 me-5">
          <!--begin::Title-->
          <div class="fs-1 fs-lg-2qx fw-bolder text-white mb-2">Não perca mais tempo!</div>
          <!--end::Title-->
          <!--begin::Description-->
          <div
            class="fs-6 fs-lg-5 text-white fw-bold opacity-75"
          >Em poucos minutos, seu site passará de uma responsabilidade arriscada
          <br/>a um exemplo líder do setor de como tratar o direito a privacidade de maneira correta.</div>
          <!--end::Description-->
        </div>
        <!--end::Content-->
        <!--begin::Link-->
        <a
          href="https://app.consensu.io/register"
          class="btn btn-lg btn-outline border-2 btn-outline-white"
        >CRIAR CONTA</a>
        <!--end::Link-->
      </div>
      <!--end::Highlight-->
    </div>
    <!--end::Container-->
  </div>
</template>

<script>
  export default {
    name: "Testimonials",
    data() {
      return {
        testimonials: [
          {
            avatar: "assets/media/avatars/150-2.jpg",
            name: "Rafael",
            company: "Consensu.io",
            site: "https://consensu.io",
            title: "Melhor plataforma para LGPD",
            text: "O Lorem Ipsum é um texto modelo da indústria tipográfica e de impressão. O Lorem Ipsum tem vindo a ser o texto padrão usado por estas indústrias desde o ano de 1500, quando uma misturou os caracteres de um texto para criar um espécime de livro.",
            rating: 5,
          },
          {
            avatar: "assets/media/avatars/150-2.jpg",
            name: "Rafael",
            company: "Consensu.io",
            site: "https://consensu.io",
            title: "Melhor plataforma para LGPD",
            text: "O Lorem Ipsum é um texto modelo da indústria tipográfica e de impressão. O Lorem Ipsum tem vindo a ser o texto padrão usado por estas indústrias desde o ano de 1500, quando uma misturou os caracteres de um texto para criar um espécime de livro.",
            rating: 5,
          },
          {
            avatar: "assets/media/avatars/150-2.jpg",
            name: "Rafael",
            company: "Consensu.io",
            site: "https://consensu.io",
            title: "Melhor plataforma para LGPD",
            text: "O Lorem Ipsum é um texto modelo da indústria tipográfica e de impressão. O Lorem Ipsum tem vindo a ser o texto padrão usado por estas indústrias desde o ano de 1500, quando uma misturou os caracteres de um texto para criar um espécime de livro.",
            rating: 5,
          },
        ],
      };
    },
  };
</script>

