<template>
  <div class="mb-0">
    <!--begin::Curve top-->
    <div class="landing-curve landing-dark-color">
      <svg
        viewBox="15 -1 1470 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 48C4.93573 47.6644 8.85984 47.3311 12.7725 47H1489.16C1493.1 47.3311 1497.04 47.6644 1501 48V47H1489.16C914.668 -1.34764 587.282 -1.61174 12.7725 47H1V48Z"
          fill="currentColor"
        />
      </svg>
    </div>
    <!--end::Curve top-->
    <!--begin::Wrapper-->
    <div class="landing-dark-bg pt-20">
      <!--begin::Container-->
      <div class="container">
        <!--begin::Row-->
        <div class="row py-10 py-lg-20">
          <!--begin::Col-->
          <div class="col-lg-6 pe-lg-16 mb-10 mb-lg-0">
            <!--begin::Block-->
            <div class="rounded landing-dark-border p-9 mb-10">
              <!--begin::Title-->
              <h2 class="text-white">Precisa de um projeto customizado?</h2>
              <!--end::Title-->
              <!--begin::Text-->
              <span class="fw-normal fs-4 text-gray-700">
                Envie mais detalhes para o nosso time através do contato 
                <a
                  href="mailto:contato@consensu.io"
                  class="text-white opacity-50 text-hover-primary"
                >contato@consensu.io</a>
              </span>
              <!--end::Text-->
            </div>
            <!--end::Block-->
            <!--begin::Block-->
            <div class="rounded landing-dark-border p-9">
              <!--begin::Title-->
              <!-- <h2 class="text-white">Que tal um projeto personalizado?</h2> -->
              <!--end::Title-->
              <!--begin::Text-->
              <span class="fw-normal fs-4 text-white">
               Fale Conosco
                <br />
                 <ul class="contacts">
                  <li>Email:  <a href="mailto:contato@consensu.io">contato@consensu.io</a></li>
                  <li>Fone:  +55 11 2626-5208</li>
                  <li>WhatsApp:  <a href="https://web.whatsapp.com/send?phone=5511950563199" target="_blank">+55 11 950563199</a></li>                  
                </ul>
                <!-- <a
                  href="mailto:contato@consensu.io"
                  class="text-white opacity-50 text-hover-primary"
                >Clique para obter um orçamento</a> -->
              </span>
              <!--end::Text-->
            </div>
            <!--end::Block-->
          </div>
          <!--end::Col-->
          <!--begin::Col-->
          <div class="col-lg-6 ps-lg-16">
            <!--begin::Navs-->
            <div class="d-flex justify-content-center">
              <!--begin::Links-->
              <div class="d-flex fw-bold flex-column me-20">
                <!--begin::Subtitle-->
                <h4 class="fw-bolder text-gray-400 mb-6">Ficou com alguma dúvida?</h4>
                <!--end::Subtitle-->
                <!--begin::Link-->
                <router-link
                 to="privacy-policy"
                  class="text-white opacity-50 text-hover-primary fs-5 mb-6"
                >Política de privacidade</router-link>
                <!--end::Link-->
                <!--begin::Link-->
                <router-link
                  to="terms-of-use"
                  class="text-white opacity-50 text-hover-primary fs-5 mb-6"
                >Termos de uso</router-link>
                <!--end::Link-->
                <!--begin::Link-->
                <!-- <a
                  href="#"
                  class="text-white opacity-50 text-hover-primary fs-5 mb-6"
                >Documentações</a> -->
                <!--end::Link-->
                <!--begin::Link-->
                <!-- <a
                  href="#"
                  class="text-white opacity-50 text-hover-primary fs-5 mb-6"
                >Artigos</a> -->
                <!--end::Link-->
              </div>
              <!--end::Links-->
              <!--begin::Links-->
              <div class="d-flex fw-bold flex-column ms-lg-20">
                <!--begin::Subtitle-->
                <h4 class="fw-bolder text-gray-400 mb-6">Conecte-se com a gente</h4>
                <!--end::Subtitle-->
                
                <!--begin::Link-->
                <a href="https://github.com/consensu-io" target="_blank" class="mb-6">
                  <img
                    src="../assets/media/svg/brand-logos/github.svg"
                    class="h-20px me-2"
                    alt
                  />
                  <span
                    class="text-white opacity-50 text-hover-primary fs-5 mb-6"
                  >Github</span>
                </a>
                <!--end::Link-->
                <!--begin::Link-->
                <!-- <a href="#" class="mb-6">
                  <img
                    src="../assets/media/svg/brand-logos/twitter.svg"
                    class="h-20px me-2"
                    alt
                  />
                  <span
                    class="text-white opacity-50 text-hover-primary fs-5 mb-6"
                  >Twitter</span>
                </a> -->
                <!--end::Link-->

                <!--begin::Link-->
                <!-- <a href="#" class="mb-6">
                  <img
                    src="../assets/media/svg/brand-logos/instagram-2-1.svg"
                    class="h-20px me-2"
                    alt
                  />
                  <span
                    class="text-white opacity-50 text-hover-primary fs-5 mb-6"
                  >Instagram</span>
                </a> -->
                <!--end::Link-->
              </div>
              <!--end::Links-->
            </div>
            <!--end::Navs-->
          </div>
          <!--end::Col-->
        </div>
        <!--end::Row-->
      </div>
      <!--end::Container-->
      <!--begin::Separator-->
      <div class="landing-dark-separator"></div>
      <!--end::Separator-->
      <!--begin::Container-->
      <div class="container">
        <!--begin::Wrapper-->
        <div class="d-flex flex-column flex-md-row flex-stack py-7 py-lg-10">
          <!--begin::Copyright-->
          <ul
            class="menu menu-gray-600 menu-hover-primary fw-bold fs-6 fs-md-5 order-1 mb-5 mb-md-0"
          >
            <li class="menu-item">
              <a
                href="#"
                target="_blank"
                class="menu-link px-2"
              >Consensu.io é uma solução registrada no CNPJ 22.596.420/0001­-15</a>
            </li>
          </ul>
          <!--end::Copyright-->
          <!--begin::Menu-->
          <ul
            class="menu menu-gray-600 menu-hover-primary fw-bold fs-6 fs-md-5 order-1 mb-5 mb-md-0"
          >
            <li class="menu-item">
              <a
                href="#"
                target="_blank"
                class="menu-link px-2"
              >Desenvolvido com muita dedicação e ❤️ especialmente para sua empresa / website.</a>
            </li>
          </ul>
          <!--end::Menu-->
        </div>
        <!--end::Wrapper-->
      </div>
      <!--end::Container-->
    </div>
    <!--end::Wrapper-->
  </div>
</template>
<style>
.contacts {
  list-style-type: square;
}
</style>

<script>
  export default {
    name: "Footer",
    computed: {
      year() {
        let date = new Date();
        return date.getFullYear();
      },
    },
  };
</script>
