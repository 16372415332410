<template>
<div class="mb-n10 mb-lg-n20 z-index-2">
   <!--begin::Container-->
   <div class="container">
      <!--begin::Heading-->
      <div class=" mb-17">
         <!--begin::Title-->
         <h2
            class="mb-5 mt-5"
            id="subtitle"
            style="text-transform: uppercase; color: #00A6A6"
            data-kt-scroll-offset="{default: 100, lg: 150}"
            >
            DO CONTRATO E DE SUA ACEITAÇÃO
         </h2>
         <!--end::Title-->
         <!--begin::Text-->
         <div class="fs-5 text-muted fw-bold">
            <p>
               A plataforma da <b>Consensu - Privacity Tech</b>, oferece diversas soluções que auxiliam no processo de administração de consentimentos e atividades de conformidade exigidos na Lei Geral de Proteção de Dados (LGPD - 13709/2018).
            </p>
            <p>
               Ao inscrever-se, cadastrar-se, acessar ou usar qualquer um dos nossos serviços você estará aceitando um contrato junto a pessoa jurídica:
               </p>

<p>
                  <b>LEMON BIT TECNOLOGIA</b> <br>
                  <b>Endereço: R. Joao Simoes de Souza, 391 - Bloco 2 Sala 114, São Paulo, Brasil</b> <br>
                  <b>CEP: 05734-140</b> <br>
         </p>
<p>Também declara-se que concorda com nossa Política de Privacidade, assim como com toda regulamentação e legislação aplicáveis, declarando-se responsável pelo cumprimento destas, e responsabilizando-se por todos e quaisquer atos praticados por você na utilização destes serviços.</p>

<p>Caso você não concorde com os termos de uso e/ou com nossa política, você não deverá utilizar e acessar os nossos serviços.</p>

<p>Não é permitido o cadastro, acesso ou uso por menores de 18 anos.</p>

<p>Ao inscrever-se, acessar, ou cadastrar-se você declara que possui 18 anos ou mais.</p>

<p>Quando você se cadastrar, se inscrever ou acessar nossos serviços vinculando-se a uma empresa, entidade ou organização, você declara e garante que:</p>

<ul>
<li>Tem autorização desta empresa, entidade ou organização;</li>
<li>Tem autoridade para vincular a empresa, entidade ou organização a estes termos e nossas políticas;</li>
<li>Autoriza e concorda que a empresa, entidade ou organização está e ficará vinculada por estes Termos e nossa política.</li>
</ul>
<p>Comprovado, de qualquer forma, que as informações prestadas pelo usuário no momento da inscrição ou acesso são falsas, o usuário está ciente e concorda que tal prática ocasionará na exclusão do respectivo cadastro.</p>

 <p>Se você se registrou para usar determinados Serviços e / ou Software em caráter experimental, poderá usar tais Serviços e Software apenas para fins de avaliação não comercial durante o período de teste aplicável. </p>

         </div>
         <!--end::Text-->
         <!--begin::Title-->
         <h2
            class="mb-5 mt-5"
            id="subtitle"
            style="text-transform: uppercase; color: #00A6A6"
            data-kt-scroll-offset="{default: 100, lg: 150}"
            >UTILIZAÇÃO E TERMOS</h2>
         <div class="fs-5 text-muted fw-bold">
      

<p>
A <b><b>Consensu - Privacity Tech</b></b> conta com diferentes soluções separadas em módulos. Todas as funcionalidades que você obtém estão descritas  nossa Página de Preços (https://www.consensu.io/#pricing ).
</p>
<p>
Algumas funcionalidades podem estar disponíveis nas diferentes versões, de modo que possibilita a realização de demonstrações gratuitas.
</p>
<p>
Você pode assinar nossos serviços, mediante o pagamento de uma taxa mensal, conforme os valores que constarão descritos em nossa Página de Preços ( <a href="/#pricing"> https://www.consensu.io/#pricing </a>).
</p>
<p>
Ao assinar estes serviços, você terá uma concessão, uma licença temporária, não transferível a que título for, e sob esta licença você não pode:
</p>

<ul>
<li>Modificar ou copiar;</li>
<li>Utilizar para qualquer fim comercial, ou para qualquer exibição pública (comercial ou não comercial);</li>
<li>Realizar, permitir ou autorizar, procedimento de engenharia reversa, desmontagem ou pirataria de qualquer aspecto do serviço;</li>
<li>Remover quaisquer direitos autorais ou outras notações proprietárias;</li>
<li>Transferir para outra pessoa ou “cópia espelho” em qualquer outro servidor.</li>
<li>Compartilhar a assinatura com outros usuários. O compartilhamento ou outros métodos que permitem que vários usuários acessem a mesma conta são estritamente proibidos</li>
<li>Esta licença cessará automaticamente se você violar qualquer destas restrições, sem prejuízo a eventuais indenizações por perdas e danos.</li>
</ul>

<p>
PAGAMENTO
Os pagamentos de assinatura podem ser concluídos por meio de serviços de terceiros.
</p>

<p>
Não armazenamos ou compartilhamos nenhuma informação pessoal ou financeira associada a um pagamento. Se o período de associação paga for limitado, um pagamento periódico pode ser criado automaticamente no sistema de pagamento de sua escolha para estender sua associação quando o período inicial de associação terminar.
</p>
<p>
Você pode cancelar este pagamento periódico automático diretamente através do painel administrativo. O preço exato a ser cobrado é calculado automaticamente usando os preços da página de preços e fornecido em suas informações de faturamento. A cobrança final, incluindo todos os impostos, será exibida antes que o pedido seja feito.
</p>
<p>
A plataforma <b>Consensu - Privacity Tech</b>, reserva-se no direito de promover alterações no preço das assinaturas de tempos em tempos, no entanto quando houver modificação nestes valores, não haverá prejuízo ao período atual da assinatura, vindo somente a surtir efeito no próximo vencimento.
</p>


         </div>
         <!--end::Title-->

  <h2
            class="mb-5 mt-5"
            id="subtitle"
            style="text-transform: uppercase; color: #00A6A6"
            data-kt-scroll-offset="{default: 100, lg: 150}"
            >FORMATOS DE USO</h2>
         <div class="fs-5 text-muted fw-bold">
<p>Todos os textos, imagens, vídeos, informações, ícones, softwares, áudios, logotipos, designs, códigos de computador e outros conteúdos contidos em nosso serviço e site, são de nossa propriedade exclusiva, estando assim, protegidos por leis de direitos autorais, marcas e outras.</p>

<p>Você concorda que não irá usá-los ou exibi-los publicamente, de qualquer forma, sem nossa prévia autorização por escrito.</p>
            </div>

           <h2
            class="mb-5 mt-5"
            id="subtitle"
            style="text-transform: uppercase; color: #00A6A6"
            data-kt-scroll-offset="{default: 100, lg: 150}"
            >ISENÇÕES E GARANTIAS</h2>
         <div class="fs-5 text-muted fw-bold">
<p>Os produtos e serviços <b>Consensu - Privacity Tech</b> são fornecidos, sem garantia de qualquer tipo, expressa ou implícita. Em nenhuma circunstância a pessoa jurídica do <b>Consensu - Privacity Tech</b> será responsável por quaisquer danos, incluindo, mas não se limitando a, danos diretos, indiretos, especiais, incidentais ou conseqüenciais ou outras perdas decorrentes do uso ou incapacidade de usar os produtos  <b>Consensu - Privacity Tech</b> ou serviços.</p>

<p>Não podemos garantir que nossos produtos ou serviços funcionarão com todos os sites, pois alguns serviços de criação de sites não permitem a inclusão de arquivos JavaScript externos. Isso pode resultar na funcionalidade incorreta do <b>Consensu - Privacity Tech</b>.</p>
            </div>

            <h2
            class="mb-5 mt-5"
            id="subtitle"
            style="text-transform: uppercase; color: #00A6A6"
            data-kt-scroll-offset="{default: 100, lg: 150}"
            >DA RESPONSABILIDADE E INDENIZAÇÕES</h2>
         <div class="fs-5 text-muted fw-bold">

<p>Você concorda expressamente que é de sua exclusiva responsabilidade o uso do serviço.</p>

<p>A <b>Consensu - Privacity Tech</b> jamais será responsável por quaisquer danos, diretos ou indiretos, incidentais e outros, que sejam resultantes do acesso e uso deste site ou desses serviços.</p>

<p>Você concorda que não seremos responsabilizados pelo que possa ocorrer com você e que esteja ligado a sua utilização de nossos serviços, bem como, não somos responsáveis pela perda ou dano que possa ocorrer como resultado de alguém usar sua senha ou conta, com ou sem o seu conhecimento.</p>

<p>Você também é totalmente responsável por todas as atividades que ocorrem através do uso de sua conta e senha. Deste modo, você concorda em nos notificar imediatamente sobre qualquer uso não autorizado de sua conta ou senha ou qualquer outra violação de segurança e garantir que você saia da sua conta ao final de cada sessão.</p>

<p>Nenhum conteúdo apresentado neste site deve ser considerado como aconselhamento profissional ou jurídico ou uma recomendação formal. Em nenhum caso a <b>Consensu - Privacity Tech</b> será responsável por quaisquer danos incidentais, indiretos, conseqüenciais ou especiais de qualquer tipo, ou quaisquer danos, incluindo, sem limitação, aqueles resultantes de perda de lucros, perda de contratos, boa vontade, dados, informações, receitas, economias antecipadas ou relações comerciais, sejam ou não avisadas da possibilidade de tais danos, decorrentes ou em conexão com o uso deste site ou de quaisquer sites vinculados</p>

<p>O <b>Consensu - Privacity Tech</b> não é responsável por multas que os você possam receber por não seguir a Diretiva de privacidade eletrônica  Lei Geral de Proteção de Dados (LGPD - 13709/2018), ou quaisquer outras leis, diretivas ou regulamentos. É responsabilidade do usuário garantir que seu site esteja em conformidade com a LGPD.</p>

<p>O <b>Consensu - Privacity Tech</b> foi projetado para fornecer os meios técnicos necessários para adicionar mensagens pop-up de cookies nos sites dos usuários e limpar os cookies antes do consentimento do usuário (se possível). </p>

<p>Embora não possamos controlar todos os sites ou clientes que usam <b>Consensu - Privacity Tech</b>, não oferecemos suporte ou permitimos o uso de nossos produtos ou serviços em sites adultos ou de jogos de azar ou com conteúdo ilegal.</p>

<h3 class="text-muted fw-bold">LIMITAÇÃO DE RESPONSABILIDADE POR PERDA OU DANO</h3>
<p>Em conjunto com a Isenção de Responsabilidade e Garantia conforme explicado acima, você expressamente entende e concorda que qualquer reclamação contra nós será limitada ao valor que você pagou, se houver, pelo uso dos serviços. A <b>Consensu - Privacity Tech</b> não será responsável por qualquer perda ou dano direto, indireto, incidental, consequencial ou exemplar em que você possa incorrer como resultado do uso de nossos serviços, ou como resultado de quaisquer alterações, perda ou corrupção de dados, cancelamento, perda de acesso ou tempo de inatividade em toda a extensão em que se aplicam as leis de limitação de responsabilidade aplicáveis.</p>

            </div>



 <h2
            class="mb-5 mt-5"
            id="subtitle"
            style="text-transform: uppercase; color: #00A6A6"
            data-kt-scroll-offset="{default: 100, lg: 150}"
            >ALTERAÇÕES DOS TERMOS DE USO</h2>
         <div class="fs-5 text-muted fw-bold">
<p>Ocasionalmente, nós podemos fazer algumas alterações no presente termo de uso, seja para melhorar nosso serviço, acrescentar novas soluções, realizar ajustes técnicos, para segurança, por outras razões legais ou regulamentares.</p>

<p>Quando isto se concretizar, será fornecido um aviso de acordo com as circunstâncias, por exemplo, exibindo uma notificação proeminente ou buscando sua confirmação dentro do serviço ou enviando um e-mail para você.</p>

<p> Em alguns casos, nós te notificaremos com antecedência, sendo que o seu uso continuado do Serviço, após terem sido feitas as alterações, constituirá sua aceitação.</p>

<p> Portanto, por favor certifique-se de ler cuidadosamente qualquer notificação.</p>

<p> Acaso você não deseje prosseguir utilizando o serviço sob a nova versão dos termos e/ou políticas, você poderá encerrar sua conta entrando em contato conosco através do email: <a href="mailto:privacidade@consensu.io">privacidade@consensu.io</a></p>
         </div>



 <h2
            class="mb-5 mt-5"
            id="subtitle"
            style="text-transform: uppercase; color: #00A6A6"
            data-kt-scroll-offset="{default: 100, lg: 150}"
            >VIOLAÇÃO DOS TERMOS</h2>
         <div class="fs-5 text-muted fw-bold">
<p>A Consensu reserva-se no direito de encerrar o seu acesso aos serviços, caso seu uso deste seja inadequado e/ou contrário às previsões deste termo.</p>

<p>Você concorda e se responsabiliza em nos indenizar e isentar por quaisquer reivindicações ou demandas, incluindo honorários advocatícios, decorrentes ou relacionadas ao seu uso dos serviços ou sua violação destes Termos ou dos direitos de terceiros.</p>

<p>Podemos negar a você o acesso a todos ou parte dos Serviços ou encerrar sua conta com ou sem aviso prévio se você se envolver em qualquer conduta ou atividades que a <b>Consensu - Privacity Tech</b> determine, a seu exclusivo critério, violar este Contrato ou os direitos da Privado ou de terceiros parte, ou é de outra forma inadequada.</p>

<p>Após o encerramento de sua (s) conta (s) por qualquer motivo, seu direito de usar os Serviços e de acessar o Site e qualquer de seus conteúdos cessará imediatamente e todo o conteúdo de suas contas será excluído. Todas as disposições deste Contrato que, por sua natureza, devam sobreviver à rescisão, sobreviverão à rescisão.</p>
         </div>



<h2
            class="mb-5 mt-5"
            id="subtitle"
            style="text-transform: uppercase; color: #00A6A6"
            data-kt-scroll-offset="{default: 100, lg: 150}"
            >SERVICOS BETA</h2>
         <div class="fs-5 text-muted fw-bold">
<p>Podemos, de tempos em tempos, oferecer acesso a serviços classificados como versão Beta. O acesso e o uso de versões beta podem estar sujeitos a acordos adicionais. A <b>Consensu - Privacity Tech</b> não faz representações de que uma versão Beta será disponibilizada de forma geral e se reserva o direito de descontinuar ou modificar uma versão Beta a qualquer momento sem aviso prévio. As versões beta são fornecidas NO ESTADO EM QUE SE ENCONTRAM, podem conter bugs, erros ou outros defeitos, e o uso de uma versão beta é por sua conta e risco.</p>
         </div>


<h2
            class="mb-5 mt-5"
            id="subtitle"
            style="text-transform: uppercase; color: #00A6A6"
            data-kt-scroll-offset="{default: 100, lg: 150}"
            >DÚVIDAS</h2>
         <div class="fs-5 text-muted fw-bold">
            <p>Quaisquer dúvidas referentes ao presente termo de uso, deverão ser encaminhadas para: <a href="mailto:privacidade@consensu.io">privacidade@consensu.io</a></p>
         </div>

<h2
            class="mb-5 mt-5"
            id="subtitle"
            style="text-transform: uppercase; color: #00A6A6"
            data-kt-scroll-offset="{default: 100, lg: 150}"
            >DA LEGISLAÇÃO E JURISDIÇÃO</h2>
         <div class="fs-5 text-muted fw-bold">
            <p>Estes termos e a relação decorrente das ações aqui compreendidas, assim como qualquer disputa que surja em virtude disto será regulada exclusivamente pela legislação brasileira.</p>
         </div>
      </div>
      
      <!--end::Heading-->
   </div>
   <!--end::Container-->
</div>
</template>

<script>
  export default {
    name: "Steps",
  };
</script>
