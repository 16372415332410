<template>
  <div class="d-flex flex-column flex-root">
    <ConsensuHeader></ConsensuHeader>
    <Steps></Steps>
    <LGPD></LGPD>
    <!-- <Team></Team> -->
    <Projects></Projects>
    <Pricing></Pricing>
    <Testimonials></Testimonials>
    <Footer></Footer>
    <ScrollTop></ScrollTop>
  </div>
</template>

<script>
  import ConsensuHeader from "@/components/Header";
  import Steps from "@/components/Steps";
  import LGPD from "@/components/LGPD";
  // import Team from "@/components/Team";
  import Projects from "@/components/Projects";
  import Pricing from "@/components/Pricing";
  import Testimonials from "@/components/Testimonials";
  import Footer from "@/components/Footer";
  import ScrollTop from "@/components/ScrollTop";

  export default {
    name: "Home",
    components: {
      ConsensuHeader,
      Steps,
      LGPD,
      // Team,
      Projects,
      Pricing,
      Testimonials,
      Footer,
      ScrollTop,
    },
  };
</script>
