<template>
  <div class="mt-sm-n20">
    <!--begin::Curve top-->
    <div class="landing-curve landing-dark-color">
      <svg
        viewBox="15 -1 1470 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 48C4.93573 47.6644 8.85984 47.3311 12.7725 47H1489.16C1493.1 47.3311 1497.04 47.6644 1501 48V47H1489.16C914.668 -1.34764 587.282 -1.61174 12.7725 47H1V48Z"
          fill="currentColor"
        />
      </svg>
    </div>
    <!--end::Curve top-->
    <!--begin::Wrapper-->
    <div class="py-20 landing-dark-bg">
      <!--begin::Container-->
      <div class="container">
        <!--begin::Plans-->
        <div class="d-flex flex-column container pt-lg-20">
          <!--begin::Heading-->
          <div class="mb-13 text-center">
            <h1
              class="fs-2hx fw-bolder text-white mb-5"
              id="pricing"
              data-kt-scroll-offset="{default: 100, lg: 150}"
            >Simples e Transparente</h1>
            <div class="text-gray-600 text-center fw-bold fs-5">
              Uma assinatura mensal por domínio determinada pelo número de páginas.
              <br />Acreditamos que o gerenciamento de consentimento deve ser fácil e acessível para qualquer pessoa / empresa.
            </div>
          </div>
          <!--end::Heading-->
          <!--begin::Pricing-->
          <div class="text-center" id="kt_pricing">
            <!--begin::Nav group-->
            <div
              class="nav-group landing-dark-bg d-inline-flex mb-15"
              data-kt-buttons="true"
              style="border: 1px dashed #2B4666;"
            >
              <a
                href="#"
                class="btn btn-color-gray-600 btn-active btn-active-success px-6 py-3 me-2 active"
                data-kt-plan="month"
              >Mensal</a>
              <a
                href="#"
                class="btn btn-color-gray-600 btn-active btn-active-success px-6 py-3"
                data-kt-plan="annual"
              >Anual</a>
            </div>
            <!--end::Nav group-->
            <!--begin::Row-->
            <div class="row g-10">
              <!--begin::Col-->
              <div class="col-xl-4" v-for="(plan,j) in plans" :key="j">
                <div class="d-flex h-100 align-items-center">
                  <!--begin::Option-->
                  <div
                    class="w-100 d-flex flex-column flex-center rounded-3 py-15 px-10"
                    :class="plan.class.color"
                  >
                    <!--begin::Heading-->
                    <div class="mb-7 text-center">
                      <!--begin::Title-->
                      <h1
                        class="mb-5 fw-boldest"
                        :class="plan.class.title"
                      >{{plan.name}}</h1>
                      <!--end::Title-->
                      <!--begin::Description-->
                      <div
                        class="fw-bold mb-5"
                        :class="plan.class.text"
                      >{{plan.desc}}</div>
                      <!--end::Description-->
                      <!--begin::Price-->
                      <div class="text-center" v-if="plan.price">
                        <span class="mb-2" :class="plan.class.text">R$</span>
                        <span
                          class="fs-3x fw-bolder"
                          :class="plan.class.text"
                          :data-kt-plan-price-month="plan.price.month"
                          :data-kt-plan-price-annual="plan.price.yearly"
                        >{{plan.price.month}}</span>
                        <span
                          class="fs-7 fw-bold opacity-50"
                          :class="plan.class.text"
                          data-kt-plan-price-month="/ Mês"
                          data-kt-plan-price-annual="/ Ano"
                        >/ Mês</span>
                      </div>

                      <div class="text-center" v-else>
                        <span
                          class="fs-3x fw-bolder"
                          :class="plan.class.text"
                        >Grátis</span>
                      </div>
                      <!--end::Price-->
                    </div>
                    <!--end::Heading-->
                    <!--begin::Features-->
                    <div class="w-100 mb-10">
                      <!--begin::Item-->
                      <div
                        class="d-flex flex-stack mb-5"
                        v-for="(opt,i) in plan.options"
                        :key="i"
                      >
                        <span
                          class="fw-bold fs-6 text-start pe-3"
                          :class="plan.class.text"
                        >{{opt.name}}</span>
                        <!--begin::Svg Icon | path: icons/duotune/general/gen043.svg-->
                        <span
                          class="svg-icon svg-icon-1"
                          :class="plan.class.icon"
                          v-if="opt.have"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <rect
                              opacity="0.3"
                              x="2"
                              y="2"
                              width="20"
                              height="20"
                              rx="10"
                              fill="black"
                            />
                            <path
                              d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                              fill="black"
                            />
                          </svg>
                        </span>
                        <span
                          class="svg-icon svg-icon-1"
                          :class="plan.class.iconError"
                          v-else
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <rect
                              opacity="0.3"
                              x="2"
                              y="2"
                              width="20"
                              height="20"
                              rx="10"
                              fill="black"
                            />
                            <rect
                              x="7"
                              y="15.3137"
                              width="12"
                              height="2"
                              rx="1"
                              transform="rotate(-45 7 15.3137)"
                              fill="black"
                            />
                            <rect
                              x="8.41422"
                              y="7"
                              width="12"
                              height="2"
                              rx="1"
                              transform="rotate(45 8.41422 7)"
                              fill="black"
                            />
                          </svg>
                        </span>
                        <!--end::Svg Icon-->
                      </div>
                      <!--end::Item-->
                    </div>
                    <!--end::Features-->
                    <!--begin::Select-->
                    <a
                      :href="`https://app.consensu.io/register?plan=${plan.name}`"
                      :class="plan.class.btn"
                    >Selecionar</a>
                    <!--end::Select-->
                  </div>
                  <!--end::Option-->
                </div>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Row-->
          </div>
          <!--end::Pricing-->
        </div>
        <!--end::Plans-->
      </div>
      <!--end::Container-->
    </div>
    <!--end::Wrapper-->
    <!--begin::Curve bottom-->
    <div class="landing-curve landing-dark-color">
      <svg
        viewBox="15 12 1470 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 11C3.93573 11.3356 7.85984 11.6689 11.7725 12H1488.16C1492.1 11.6689 1496.04 11.3356 1500 11V12H1488.16C913.668 60.3476 586.282 60.6117 11.7725 12H0V11Z"
          fill="currentColor"
        />
      </svg>
    </div>
    <!--end::Curve bottom-->
  </div>
</template>

<script>
  export default {
    name: "HelloWorld",
    data() {
      return {
        plans: [
          {
            name: "Start",
            class: {
              color: "bg-body",
              title: "text-dark",
              text: "text-primary",
              icon: "svg-icon-success",
              iconError: "",
              btn: "btn btn-primary",
            },
            desc: "Melhor configuração para startups",
            price: {
              month: "5,90",
              yearly: "58,80",
            },
            options: [
              { name: "1 Domínio", have: true },
              { name: "1 conta", have: true },
              { name: "15k Visitantes Únicos / Mês", have: true },
              { name: "Banner Consentimento", have: true },
              { name: "Bloqueio Automatico de Cookies", have: true },
              { name: "Paginas Ilimitadas", have: true },
              { name: "Identificaçao de Cookies Automatica", have: true },
              { name: "Opt-out através do seu website", have: true },
              { name: "Selo seguro by Consensu", have: true },
              { name: "Integração via GTM", have: true },
              { name: "Plugin para Wordpress", have: true },
              { name: "Cores e Textos Customizados", have: false },
              { name: "Politica de Privacidade", have: false },
              { name: "Termos de Uso", have: false },
              { name: "Estatisticas de Consentimento", have: false },
              { name: "Auditoria de Consentimento", have: false },
              // { name: "Utilize seu logotipo", have: false },
              { name: "Esconder aviso após aceite", have: false },
              { name: "Exportação de dados", have: false },
              { name: "Acesso a API de Consentimento", have: false },
              { name: "Reagendamento de Identificação de Cookies", have: false },
              { name: "Politica de Privacidade de Cookies", have: false },
            ],
          },
          {
            name: "Professional",
            class: {
              color: "bg-primary",
              title: "text-white",
              text: "text-white",
              icon: "svg-icon-white",
              iconError: "svg-icon-white",
              btn: "btn btn-color-primary btn-active-light-primary btn-light",
            },
            desc: "Melhor configuração para negócios",
            price: {
              month: "39,90",
              yearly: "359,10",
            },
            options: [
              { name: "10 Domínios", have: true },
              { name: "10 contas", have: true },
              { name: "1 Milhão Visitantes Únicos / Mês", have: true },
              { name: "Banner Consentimento", have: true },
              { name: "Bloqueio Automatico de Cookies", have: true },
              { name: "Paginas Ilimitadas", have: true },
              { name: "Identificaçao de Cookies Automatica", have: true },
              { name: "Opt-out através do seu website", have: true },
              { name: "Selo seguro by Consensu", have: true },
              { name: "Integração via GTM", have: true },
              { name: "Plugin para Wordpress", have: true },
              { name: "Cores e Textos Customizados", have: true },
              { name: "Politica de Privacidade", have: true },
              { name: "Termos de Uso", have: true },
              { name: "Estatisticas de Consentimento", have: true },
              { name: "Auditoria de Consentimento", have: true },
              // { name: "Utilize seu logotipo", have: false },
              { name: "Esconder aviso após aceite", have: false },
              { name: "Exportação de dados", have: false },
              { name: "Acesso a API de Consentimento", have: false },
              { name: "Reagendamento de Identificação de Cookies", have: false },
              { name: "Politica de Privacidade de Cookies", have: false },
            ],
          },
          {
            name: "Enterprise",
            class: {
              color: "bg-body",
              title: "text-dark",
              text: "text-primary",
              icon: "svg-icon-success",
              iconError: "",
              btn: "btn btn-primary",
            },
            desc: "Melhor configuração para empresas",
            price: {
              month: "185",
              yearly: "1.465,20",
            },
            options: [
              { name: "Até 50 Domínios", have: true },
              { name: "Até 50 contas", have: true },
              { name: "Até 5 milhões usuário únicos", have: true },
              { name: "Banner Consentimento", have: true },
              { name: "Bloqueio Automatico de Cookies", have: true },
              { name: "Paginas Ilimitadas", have: true },
              { name: "Identificaçao de Cookies Automatica", have: true },
              { name: "Opt-out através do seu website", have: true },
              { name: "Selo seguro by Consensu", have: true },
              { name: "Integração via GTM", have: true },
              { name: "Plugin para Wordpress", have: true },
              { name: "Cores e Textos Customizados", have: true },
              { name: "Politica de Privacidade", have: true },
              { name: "Termos de Uso", have: true },
              { name: "Estatisticas de Consentimento", have: true },
              { name: "Auditoria de Consentimento", have: true },
              // { name: "Utilize seu logotipo", have: true },
              { name: "Esconder aviso após aceite", have: true },
              { name: "Exportação de dados", have: true },
              { name: "Acesso a API de Consentimento", have: true },
              { name: "Reagendamento de Identificação de Cookies", have: true },
              { name: "Politica de Privacidade de Cookies", have: true },
            ],
          },
        ],
      };
    },
  };
</script>
