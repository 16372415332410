<template>
  <div class="mb-lg-n15 position-relative z-index-2" id="funcionalidades">
    <!--begin::Container-->
    <div class="container">
      <!--begin::Card-->
      <div
        class="card"
        style="filter: drop-shadow(0px 0px 40px rgba(68, 81, 96, 0.08))"
      >
        <!--begin::Card body-->
        <div class="card-body p-lg-20">
          <!--begin::Heading-->
          <div class="text-center mb-5 mb-lg-10">
            <!--begin::Title-->
            <h3
              class="fs-2hx text-dark mb-5"
              id="portfolio"
              data-kt-scroll-offset="{default: 100, lg: 150}"
            >FUNCIONALIDADES</h3>
            <!--end::Title-->
          </div>
          <!--end::Heading-->
          <h3 id="subtitle" data-kt-scroll-offset="{default: 100, lg: 150}" class="mb-5 text-center" style="text-transform: uppercase;">
            Sempre atualizado.
          </h3>
          <!--begin::Tabs wrapper-->
          <div class="d-flex flex-center mb-5 mb-lg-15">
            <!--begin::Tabs-->
            <div
              class="fs-5 text-center text-muted fw-bold"
            >
              Verificamos automaticamente todos os cookies e rastreadores em seu website. Cookies e rastreadores serão criados apenas quando um visitante do site concede permissão para isso.
Mensalmente atualizamos a lista de cookies automaticamente em seu site. Isso garante que seu site esteja sempre em conformidade com a lei.

            </div>
            <!--end::Tabs-->
          </div>
          <div
            class="d-flex list align-items-center justify-content-between mb-5 mb-lg-15"
          >
            <div class="func-box" v-for="(func,i) in functions" :key="i">
              <div class="d-flex">
                <span class="icon">
                  <img :src="func.icon" />
                </span>
                <h3>{{func.name}}</h3>
              </div>
              <p class="fs-5 text-muted">{{func.desc}}</p>
            </div>
          </div>
          <!--end::Tabs wrapper-->
        </div>
        <!--end::Card body-->
      </div>
      <!--end::Card-->
    </div>
    <!--end::Container-->
  </div>
</template>

<script>
  export default {
    name: "Projects",
    data() {
      return {
        functions: [
          {
            icon: "../assets/media/icons/duotune/coding/cod006.svg",
            name: "Consentimento",
            desc: "Esteja em conformidade com a LGPD mantendo o consentimento em um banco de dados anônimo e criptografado.",
          },
          {
            icon: "../assets/media/icons/duotune/abstract/abs002.svg",
            name: "Rápido e Fácil",
            desc: "Em poucos minutos, você pode configurar o consentimento de cookies compatível e política para seu site.",
          },
          {
            icon: "../assets/media/icons/duotune/graphs/gra006.svg",
            name: "Relatórios",
            desc: "Uma infinidade de relatórios com insights sobre dados de consentimento, particionados por geo-localização",
          },
          {
            icon: "../assets/media/icons/duotune/general/gen002.svg",
            name: "APIs de Integração",
            desc: "Sincronize consentimentos de opt-in e opt-out entre ferramentas com nossas APIs de integração.",
          },
          {
            icon: "../assets/media/icons/duotune/technology/teh004.svg",
            name: "Auditoria",
            desc: "Você pode visualizar trilhas de auditoria dos consentimentos de seus visitantes e ver seus status em um determinado momento.",
          },
          {
            icon: "../assets/media/icons/duotune/finance/fin005.svg",
            name: "Evite Multas",
            desc: "Evite as multas de privacidade e manchetes prejudiciais. Permita que seu visitante controle o que você captura.",
          },
          {
            icon: "../assets/media/icons/duotune/art/art003.svg",
            name: "Personalizável",
            desc: "Utilize suas cores, estilos, efeitos e posicionamento. Para deixar o seu box de consentimento com a cara dos seus sites.",
          },
          {
            icon: "../assets/media/icons/duotune/abstract/abs027.svg",
            name: "Módulos",
            desc: "Diversos módulos de integração como Wordpress, GTM e muito mais...",
          },
          {
            icon: "../assets/media/icons/duotune/coding/cod007.svg",
            name: "Controle automático",
            desc: "Bloqueio automático de todos os cookies primários e de terceiros em seu site até que o consentimento seja dado.",
          },
        ],
      };
    },
  };
</script>

<style scoped>
  .list {
    display: flex;
    flex-wrap: wrap;
  }
  .func-box {
    border: 1px dashed #e4e6ef;
    border-radius: 5px;
    padding: 15px;
    margin: 10px 10px;
    flex: 0 0 30%;
    height: 150px;
  }
  .func-box div {
    border-bottom: 1px dashed #e4e6ef;
  }
  .func-box div h3 {
    margin-left: 10px;
  }
  .func-box p {
    display: block;
    padding-top: 10px;
  }
  @media only screen and (max-width: 600px) {
    .func-box {
      flex: 0 0 95% !important;
    }
  }
</style>
