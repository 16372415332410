<template>
<div class="mb-n10 mb-lg-n20 z-index-2">
   <!--begin::Container-->
   <div class="container">
      <!--begin::Heading-->
      <div class=" mb-17">
         <!--begin::Title-->
         <h2
            class="mb-5 mt-5"
            id="subtitle"
            style="text-transform: uppercase; color: #00A6A6"
            data-kt-scroll-offset="{default: 100, lg: 150}"
            >
            Legislação de Privacidade
         </h2>
         <!--end::Title-->
         <!--begin::Text-->
         <div class="fs-5 text-muted fw-bold">
            Coletamos seus dados pessoais de acordo e em conformidade com as legislações de proteção de dados relevantes, em especial a Lei Geral de Proteção de Dados Pessoais (LGPD) – 13709/2018.
            Leia este Aviso com atenção para entender nossas práticas em relação às suas informações e como iremos tratá-las. 
         </div>
         <!--end::Text-->
         <!--begin::Title-->
         <h2
            class="mb-5 mt-5"
            id="subtitle"
            style="text-transform: uppercase; color: #00A6A6"
            data-kt-scroll-offset="{default: 100, lg: 150}"
            >QUEM SOMOS</h2>
         <div class="fs-5 text-muted fw-bold">
            <b>Consensu.IO – Privacity Tech.</b> (LEMON BIT TECNOLOGIA)
            Estamos estabelecidos na Rua João Simões de Souza, 391 - Bloco 2 Sala 114, São Paulo, Brasil, registrada sob o número 22.596.420/0001­-15. Atuamos como operador e controlador ao tratar seus dados pessoais e nosso DPO é pode ser contatado em <a href="mailto:privacidade@consensu.io">privacidade@consensu.io</a>
         </div>
         <!--end::Title-->
         <h2
            class="mb-5 mt-5"
            id="subtitle"
            style="text-transform: uppercase; color: #00A6A6"
            data-kt-scroll-offset="{default: 100, lg: 150}"
            >O QUE COLETAMOS</h2>
         <div class="fs-5 text-muted fw-bold text-right">
            <p>Tratamos suas informações pessoais para cumprir nossas obrigações legais contratuais e fornecer-lhe nossos produtos e serviços. Nunca coletamos dados pessoais desnecessários de você e não trataremos suas informações de quaisquer outras formas que não as especificadas neste Aviso.</p>
            <p>Os dados coletados são pseudoanomizados, afinal, há necessidade de vincular o cookie ID com os usuários, a fim de estabelecer uma análise do consentimento.</p>
            <span class="pre-title m-0">MANEIRAS DE COLETA:</span>
            <ul>
               <li>A coleta de dados se dá por meio de formulários,  geração da conta do usuário e ativação dos acessos ao sistema de controle de consentimento, registo de newsletters, comunicação de um problema com o nosso site ou serviços,  seu nome, e-mail, nome da empresa, endereço, número de telefone, etc. (controlador).</li>
               <li>Registros e cópias de mensagens (incluindo endereços de e-mail) quando você nos contatar.</li>
               <li>Informações técnicas e analíticas, como tipos de navegador, detalhes de uso, tempo de solicitações, sistema operacional, endereços IP, informações do Google Analytics e ferramentas semelhantes e informações coletadas por meio de cookies e outras tecnologias de rastreamento.</li>
               <li>Dados estatísticos agregados.</li>
            </ul>
            <p></p>
            <span class="pre-title m-0">INFORMAÇÕES E TERCEIROS</span>
            <p>Trabalhamos em estreita colaboração com terceiros e também recebemos informações sobre você. Isso pode incluir serviços de terceiros, como Google Analytics, serviços de processamento de pagamentos online e  outros terceiros confiáveis.</p>
            <span class="pre-title m-0">COOKIES</span>
            <p>Um cookie é um arquivo de texto que é baixado para o seu dispositivo quando você visita um site. Isso pode ajudar a identificar seu dispositivo sempre que você visitar esse site. Para fazer o melhor uso do nosso site ou do aplicativo, recomendamos que você configure seu dispositivo ou navegador da web para aceitar cookies. No entanto, você sempre pode optar por recusar nossos cookies. Se você fizer isso, talvez não consiga usar algumas partes do nosso site ou do aplicativo. Podemos usar cookies para:</p>
            <ul>
               <li>Analisar como sites e aplicativos funcionam e são usados.</li>
               <li>Dar-lhe conteúdo que corresponda às suas preferências.</li>
               <li>Alguns cookies podem nos ajudar a garantir que suas informações estejam seguras ao usar nossos serviços.</li>
               <li>Certifique-se de que as páginas do site tenham uma aparência consistente.</li>
            </ul>
            <p></p>
            <span class="pre-title m-0">INFORMAÇÕES QUE COLETAMOS E TECNOLOGIAS DE RASTREAMENTO</span>
            <p>Podemos coletar informações que não revelam diretamente sua identidade específica ou não se relacionam diretamente a você como indivíduo. Podemos coletar automaticamente as seguintes informações quando você usa nosso serviço:</p>
            <ul>
               <li>Seu dispositivo, ações de navegação e padrões coletados automaticamente enquanto você navega em nossos sites.</li>
               <li>Detalhes de uso, hora das solicitações, tipos de navegador, sistema operacional, endereços IP e informações coletadas por meio de cookies, web beacons e outras tecnologias de rastreamento.</li>
               <li>Detalhes de suas visitas ao nosso site, incluindo dados de tráfego, dados de localização, registros e outros dados de comunicação e os recursos que você acessa e usa no site.</li>
               <li>Informações sobre seu computador e conexão com a Internet, sistema operacional e tipo de navegador.</li>
            </ul>
            <p>No que tange às tratativas envolvendo a plataforma e as atividades principais de consentimento, somos operadores, e não há coletas de informações pessoais por parte da <strong>Consensu.IO Privacity Tech.</strong></p>
            <span class="pre-title m-0">DADOS PESSOAIS E SUA UTILIZAÇÃO</span>
            <p>Nós levamos sua privacidade muito a sério e nunca divulgaremos, compartilharemos ou venderemos seus dados sem o seu consentimento, a menos que seja necessário fazê-lo por lei. </p>
            <p>Mantemos seus dados pelo período necessário para que possamos atingir a finalidade especificada neste Aviso. </p>
            <ul>
               <li>Coletamos seus dados pessoais para fornecimento do serviço de gerenciamento da plataforma e fins de contabilidade empresarial e tributária. (execução de contrato)</li>
               <li>Ocasionalmente, enviaremos informações de marketing em que avaliamos o que pode ser benéfico para você como nosso consumidor. Tais informações não são intrusivas e são tratadas com base em nossos interesses legítimos. Você pode optar a qualquer momento por não receber estas informações. (legítimo interesse)</li>
            </ul>
            <p>Qualquer uma das informações que coletamos de você pode ser usada para uma ou mais das seguintes finalidades:</p>
            <ul>
               <li>Para lhe fornecer autorização para fazer login e usar sua conta de usuário;</li>
               <li>Para verificar sua identidade e idade;</li>
               <li>Manter informações corretas e atualizadas sobre você;</li>
               <li>Para identificá-lo como parte contratante;</li>
               <li>Para melhorar nosso site;</li>
               <li>para permitir o tratamento automatizado das assinaturas;</li>
               <li>De outra forma ser capaz de fornecer os Serviços a você de acordo com esta política.</li>
            </ul>
            <p></p>
         </div>
      
      <h2
         class="mb-5 mt-5"
         id="subtitle"
         style="text-transform: uppercase; color: #00A6A6"
         data-kt-scroll-offset="{default: 100, lg: 150}"
         >
         DIREITOS
      </h2>
      <!--end::Title-->
      <!--begin::Text-->
      <div class="fs-5 text-muted fw-bold">
         <p>Você tem o direito de acessar qualquer informação pessoal que nós tratamos a seu respeito, e pode também solicitar informações sobre:</p>
         <ul>
            <li>Quais dados pessoais temos sobre você</li>
            <li>As finalidades do tratamento</li>
            <li>As categorias de dados pessoais em causa</li>
            <li>Os destinatários a quem os dados pessoais foram/serão divulgados</li>
            <li>Quanto tempo pretendemos armazenar seus dados pessoais</li>
            <li>Se não coletamos os dados diretamente de você, informações sobre a fonte</li>
         </ul>
         <p>Se você acredita que temos quaisquer dados incompletos ou imprecisos sobre você, você tem o direito de nos pedir para corrigir e/ou completar as informações e nos esforçamos  para  fazê-lo o mais rapidamente possível, a menos que haja uma razão válida para não fazê-lo. Caso ocorra esta última situação você será devidamente notificado.</p>
         <p>Você também tem o direito de solicitar o apagamento de seus dados pessoais ou de restringir o tratamento (quando aplicável) de acordo com a legislação de proteção de dados, bem como o direito de se opor a qualquer marketing direto. Quando aplicável, você tem o direito à portabilidade de dados de suas informações e o direito de ser informado sobre qualquer tomada de decisão automatizada que possamos usar.</p>
         <p>Todas estas solicitações, podem ser realizadas através do endereço <strong>privacidade@consensu.io</strong></p>
         <p>Se recebermos uma solicitação sua para exercer qualquer um dos direitos acima, podemos pedir que você confirme sua identidade antes de agirmos com base em sua solicitação. Esta confirmação é necessária para garantirmos que seus dados estão protegidos e são mantidos em segurança.</p>
         <span class="pre-title m-0">INFORMAÇÕES PESSOAIS E DIVULGAÇÃO.</span>
         <p>Não compartilhamos ou divulgamos nenhum dado pessoal seu sem o seu consentimento, exceto para os fins especificados neste Aviso ou quando houver uma exigência legal. </p>
         
<h2
         class="mb-5 mt-5"
         id="subtitle"
         style="text-transform: uppercase; color: #00A6A6"
         data-kt-scroll-offset="{default: 100, lg: 150}"
         >
         MEDIDAS DE SEGURANÇA
      </h2>
     <div class="fs-5 text-muted fw-bold">
         <p>Nós levamos sua privacidade a sério e tomamos todas as medidas e precauções razoáveis para proteger seus dados pessoais. Trabalhamos duro para protegê-lo, e aos seus dados pessoais, contra acesso não autorizado, alteração acidental ou ilícita, divulgação ou destruição de seus dados pessoais, e temos várias camadas de medidas de segurança em vigor, <strong>incluindo: criptografia, pseudo-anonimização, restrição de acesso, autenticação de dois fatores (em implantação), firewalls, etc</strong></p>
         <span class="pre-title m-0">TRANSFERÊNCIA INTERNACIONAL DE DADOS.</span>
         <p>Nós utilizamos alguns produtos ou serviços (ou partes deles) que estão hospedados nos EUA, o que significa que podemos transferir qualquer informação que nos seja enviada por você para fora do território brasileiro para as finalidades abaixo indicadas.</p>
         <p>- Hospedagem do sistema de armazenamento de dados, como forma de redundância da estrutura <strong>Consensu.io Privacity Tech</strong>.</p>
         <p>Portanto, quando você <strong>[usa nosso site/envia-nos um e-mail/cadastre-se em nossa newsletter etc]</strong>, os dados pessoais que nos envia podem ser armazenados em servidores que estão hospedados nos EUA. Nesse caso, tomaremos as medidas exigidas pelas legislações de proteção de dados para garantir que esses fornecedores garantam o nível de proteção adequado para seus dados pessoais e cumpram com acordos e medidas rigorosamente estabelecidas para proteger seus dados e cumprir as leis de proteção de dados relevantes.</p>
         <p>Quando transferimos dados pessoais pelas razões acima, utilizamos as medidas e mecanismos de salvaguarda abaixo para garantir que seus dados pessoais estejam sempre seguros:</p>
         <ul>
            <li> Transferência utilizando protocolos de criptografia SSL;</li>
            <li>Regras de transferência estabelecidas via firewall 1:1;</li>
         </ul>
         <p></p>
         <span class="pre-title m-0">FORNECIMENTO DOS DADOS</span>
         <p>Você não é obrigado a fornecer seus dados pessoais para nós. No entanto, como essas informações são necessárias para fornecermos nossos serviços/entregar seus produtos/interesses legítimos, não seremos capazes de oferecer alguns/todos os nossos serviços sem seus dados.</p>
         <span class="pre-title m-0">RETENÇÃO DOS DADOS </span>
         <p>Retemos os seus registos de acordo com as orientações de melhores práticas nacionais e apenas processamos os seus dados pessoais enquanto for necessário para os fins para os quais as informações em questão são processadas, conforme descrito na secção 2. e temos rigorosas políticas de revisão e retenção em vigor para cumprir essas obrigações.</p>
         <p>Após o cumprimento do propósito das informações , todas as informações são tornadas anônimas ou deletadas em até 5 dias.</p>
         <p>Quando você nos der seu consentimento para que utilizemos seus dados para marketing direto, manteremos esses dados até que você exerça seu direito de oposição e/ou retirar seu consentimento, também conhecido como “opt-out”.</p>
         </div>
      </div>
      <h2
         class="mb-5 mt-5"
         id="subtitle"
         style="text-transform: uppercase; color: #00A6A6"
         data-kt-scroll-offset="{default: 100, lg: 150}"
         >
         FALE CONOSCO
      </h2>
      <!--end::Title-->
      <!--begin::Text-->
      <div class="fs-5 text-muted fw-bold">
         <p>Para qualquer dúvida ou preocupação, você pode nos contatar pelos canais abaixo.</p>
         <ul>
            <li>Email:  <a href="mailto:contato@consensu.io">contato@consensu.io</a></li>
            <li>Fone:  +55 11 2626-5208</li>
            <li>WhatsApp:  <a href="https://web.whatsapp.com/send?phone=5511950563199" target="_blank">+55 11 950563199</a></li>
            
         </ul>
      </div>
      </div>
      
      <!--end::Heading-->
   </div>
   <!--end::Container-->
</div>
</template>

<script>
  export default {
    name: "Steps",
  };
</script>
