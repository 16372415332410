<template>
  <div class="mb-n10 mb-lg-n20 z-index-2">
    <!--begin::Container-->
    <div class="container">
      <!--begin::Heading-->
      <div class="text-center mb-17">
        <!--begin::Title-->
        <h3
          class="fs-2hx text-dark mb-5"
          id="instalacao"
          data-kt-scroll-offset="{default: 100, lg: 150}"
        >Apenas 3 Passos</h3>
        <!--end::Title-->
        <!--begin::Text-->
        <div class="fs-5 text-muted fw-bold">
          A gestão de privacidade e consentimento não precisa ser complicada.
          <br />Em minutos você estará gerenciando o consentimento de cookies de seus usuários.
          <br />Pronto para levar a sério a privacidade de dados?
        </div>
        <!--end::Text-->
        <!--begin::Title-->
        <h3
          class="mb-5 mt-5"
          id="subtitle"
          style="text-transform: uppercase; color: #00A6A6"
          data-kt-scroll-offset="{default: 100, lg: 150}"
        >Processo de instalação simples e rápido</h3>
        <!--end::Title-->
      </div>
      <!--end::Heading-->
      <!--begin::Row-->
      <div class="row w-100 gy-10 mb-md-20">
        <!--begin::Col-->
        <div class="col-md-4 px-5">
          <!--begin::Story-->
          <div class="text-center mb-10 mb-md-0">
            <!--begin::Illustration-->
            <img src="../assets/media/steps/1.png" class="mh-150px mb-9" alt />
            <!--end::Illustration-->
            <!--begin::Heading-->
            <div class="d-flex flex-center mb-5">
              <!--begin::Badge-->
              <span
                class="badge badge-circle badge-light-success fw-bolder p-5 me-3 fs-3"
              >1</span>
              <!--end::Badge-->
              <!--begin::Title-->
              <div class="fs-5 fs-lg-3 fw-bolder text-dark">Crie sua conta</div>
              <!--end::Title-->
            </div>
            <!--end::Heading-->
            <!--begin::Description-->
            <div
              class="fw-bold fs-6 fs-lg-4 text-muted"
            >Nada de formulários sem fim. Com poucos cliques você cria uma conta</div>
            <!--end::Description-->
          </div>
          <!--end::Story-->
        </div>
        <!--end::Col-->
        <!--begin::Col-->
        <div class="col-md-4 px-5">
          <!--begin::Story-->
          <div class="text-center mb-10 mb-md-0">
            <!--begin::Illustration-->
            <img src="../assets/media/steps/2.png" class="mh-150px mb-9" alt />
            <!--end::Illustration-->
            <!--begin::Heading-->
            <div class="d-flex flex-center mb-5">
              <!--begin::Badge-->
              <span
                class="badge badge-circle badge-light-success fw-bolder p-5 me-3 fs-3"
              >2</span>
              <!--end::Badge-->
              <!--begin::Title-->
              <div
                class="fs-5 fs-lg-3 fw-bolder text-dark"
              >Categorize os Cookies</div>
              <!--end::Title-->
            </div>
            <!--end::Heading-->
            <!--begin::Description-->
            <div
              class="fw-bold fs-6 fs-lg-4 text-muted"
            >Examinamos seu site em busca de cookies e rastreadores de terceiros.</div>
            <!--end::Description-->
          </div>
          <!--end::Story-->
        </div>
        <!--end::Col-->
        <!--begin::Col-->
        <div class="col-md-4 px-5">
          <!--begin::Story-->
          <div class="text-center mb-10 mb-md-0">
            <!--begin::Illustration-->
            <img src="../assets/media/steps/3.png" class="mh-150px mb-9" alt />
            <!--end::Illustration-->
            <!--begin::Heading-->
            <div class="d-flex flex-center mb-5">
              <!--begin::Badge-->
              <span
                class="badge badge-circle badge-light-success fw-bolder p-5 me-3 fs-3"
              >3</span>
              <!--end::Badge-->
              <!--begin::Title-->
              <div class="fs-5 fs-lg-3 fw-bolder text-dark">Instale nossa TAG</div>
              <!--end::Title-->
            </div>
            <!--end::Heading-->
            <!--begin::Description-->
            <div
              class="fw-bold fs-6 fs-lg-4 text-muted"
            >Copie e cole nossa tag personalizada em sua página e siga em conformidade.</div>
            <!--end::Description-->
          </div>
          <!--end::Story-->
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->
      <!--begin::Product slider-->
      <div class="tns tns-default">
        <!--begin::Slider-->
        <div
          data-tns="true"
          data-tns-loop="true"
          data-tns-swipe-angle="false"
          data-tns-speed="2000"
          data-tns-autoplay="true"
          data-tns-autoplay-timeout="18000"
          data-tns-controls="true"
          data-tns-nav="false"
          data-tns-items="1"
          data-tns-center="false"
          data-tns-dots="false"
          data-tns-prev-button="#kt_team_slider_prev1"
          data-tns-next-button="#kt_team_slider_next1"
        >
          <!--begin::Item-->
          <div class="text-center px-5 pt-5 pt-lg-10 px-lg-10">
            <img
              src="../assets/media/consensu/dashboard.png"
              class="card-rounded shadow mw-100"
              alt
            />
          </div>
          <!--end::Item-->
          <!--begin::Item-->
          <div class="text-center px-5 pt-5 pt-lg-10 px-lg-10">
            <img
              src="../assets/media/consensu/account-dashboard.png"
              class="card-rounded shadow mw-100"
              alt
            />
          </div>
          <!--end::Item-->
          <!--begin::Item-->
          <div class="text-center px-5 pt-5 pt-lg-10 px-lg-10">
            <img
              src="../assets/media/consensu/account-banner.png"
              class="card-rounded shadow mw-100"
              alt
            />
          </div>
          <!--end::Item-->
          <!--begin::Item-->
          <div class="text-center px-5 pt-5 pt-lg-10 px-lg-10">
            <img
              src="../assets/media/consensu/cookies.png"
              class="card-rounded shadow mw-100"
              alt
            />
          </div>
          <!--end::Item-->

           <!--begin::Item-->
          <div class="text-center px-5 pt-5 pt-lg-10 px-lg-10">
            <img
              src="../assets/media/consensu/consent.png"
              class="card-rounded shadow mw-100"
              alt
            />
          </div>
          <!--end::Item-->
            <!--begin::Item-->
          <div class="text-center px-5 pt-5 pt-lg-10 px-lg-10">
            <img
              src="../assets/media/consensu/integrations.png"
              class="card-rounded shadow mw-100"
              alt
            />
          </div>
          <!--end::Item-->
        </div>
        <!--end::Slider-->
        <!--begin::Slider button-->
        <button
          class="btn btn-icon btn-active-color-primary"
          id="kt_team_slider_prev1"
        >
          <!--begin::Svg Icon | path: icons/duotune/arrows/arr074.svg-->
          <span class="svg-icon svg-icon-3x">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M11.2657 11.4343L15.45 7.25C15.8642 6.83579 15.8642 6.16421 15.45 5.75C15.0358 5.33579 14.3642 5.33579 13.95 5.75L8.40712 11.2929C8.01659 11.6834 8.01659 12.3166 8.40712 12.7071L13.95 18.25C14.3642 18.6642 15.0358 18.6642 15.45 18.25C15.8642 17.8358 15.8642 17.1642 15.45 16.75L11.2657 12.5657C10.9533 12.2533 10.9533 11.7467 11.2657 11.4343Z"
                fill="black"
              />
            </svg>
          </span>
          <!--end::Svg Icon-->
        </button>
        <!--end::Slider button-->
        <!--begin::Slider button-->
        <button
          class="btn btn-icon btn-active-color-primary"
          id="kt_team_slider_next1"
        >
          <!--begin::Svg Icon | path: icons/duotune/arrows/arr071.svg-->
          <span class="svg-icon svg-icon-3x">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M12.6343 12.5657L8.45001 16.75C8.0358 17.1642 8.0358 17.8358 8.45001 18.25C8.86423 18.6642 9.5358 18.6642 9.95001 18.25L15.4929 12.7071C15.8834 12.3166 15.8834 11.6834 15.4929 11.2929L9.95001 5.75C9.5358 5.33579 8.86423 5.33579 8.45001 5.75C8.0358 6.16421 8.0358 6.83579 8.45001 7.25L12.6343 11.4343C12.9467 11.7467 12.9467 12.2533 12.6343 12.5657Z"
                fill="black"
              />
            </svg>
          </span>
          <!--end::Svg Icon-->
        </button>
        <!--end::Slider button-->
      </div>
      <!--end::Product slider-->
    </div>
    <!--end::Container-->
  </div>
</template>

<script>
  export default {
    name: "Steps",
  };
</script>
